import React from 'react';
import { gql, useLazyQuery } from '@apollo/client';

import { ContractTypeEnum } from '@utils/translators/proposal';
import { parseStringToDate } from '@utils/text';

export type ProcessStageType = 'REQUESTED' | 'UNDER_ANALYSIS' | 'FINISHED';

const GET_PROCESS_LIST_STR = gql`
  query GetProcessListSummaryForAdmin($stage: ProcessStageType!, $page: Int, $limit: Int) {
    getProcessListSummary(stage: $stage, page: $page, limit: $limit) {
      error
      data {
        id
        name
        numBids
        numTraders
        deadline
        contractType
        totalConsumptionVolume
        group {
          name
        }
        mostRecentProposalId
        round
        mostRecentProposalProcessStatus
      }
      limit
      page
      total
    }
  }
`;

export enum ProposalProcessStatusEnum {
  FINISHED = 'Finalizado',
  FINAL_ROUND_EXPIRED = 'Expirado',
  FINAL_ROUND_WAITING = 'Aguardando',
  ONGOING_ROUND = 'Em andamento',
}

export interface RawDataEntry {
  id: string;
  name: string;
  numBids: number;
  numTraders: number;
  deadline: string;
  contractType: keyof typeof ContractTypeEnum;
  totalConsumptionVolume: number;
  group: { name: string };
  mostRecentProposalId: string;
  round: number;
  mostRecentProposalProcessStatus: keyof typeof ProposalProcessStatusEnum;
}

interface GetProcessListSummaryGraphQLResponse {
  getProcessListSummary: {
    error: string | null;
    data: RawDataEntry[] | null;
    limit: number;
    page: number;
    total: number;
  };
}

interface GetProcessListSummaryGraphQLRequest {
  stage: ProcessStageType;
  page: number | undefined;
  limit: number | undefined;
}

export interface PaginationData {
  page: number | null;
  pageLimit: number | null;
  total: number;
}

export interface ProcessSummaryData {
  id: string;
  name: string;
  numBids: number;
  numTraders: number;
  deadline: Date;
  contractType: keyof typeof ContractTypeEnum;
  totalConsumptionVolume: number;
  groupName: string;
  mostRecentProposalId: string;
  round: number;
  mostRecentProposalProcessStatus: keyof typeof ProposalProcessStatusEnum;
}

const parseListData = (rawList: RawDataEntry[]): ProcessSummaryData[] => {
  return rawList.map((entry) => {
    const {
      id,
      name,
      numBids,
      numTraders,
      deadline,
      contractType,
      totalConsumptionVolume,
      group,
      mostRecentProposalId,
      round,
      mostRecentProposalProcessStatus,
    } = entry;
    return {
      id,
      name,
      numBids,
      numTraders,
      deadline: parseStringToDate(deadline),
      contractType,
      totalConsumptionVolume,
      groupName: group.name,
      mostRecentProposalId,
      round,
      mostRecentProposalProcessStatus,
    };
  });
};

export function useGetProcessSummaryListByStage() {
  const [isRequestLoading, setIsRequestLoading] = React.useState<boolean>(true);
  const [processData, setProcessData] = React.useState<ProcessSummaryData[]>([]);
  const [queryFunction] = useLazyQuery<GetProcessListSummaryGraphQLResponse, GetProcessListSummaryGraphQLRequest>(
    GET_PROCESS_LIST_STR,
  );

  const fetchProcessListData = async (
    stage: ProcessStageType,
    page: number | undefined,
    limit: number | undefined,
  ): Promise<void> => {
    await queryFunction({
      variables: { stage, page, limit },
      onCompleted: (data) => {
        if (data.getProcessListSummary.error !== null || data.getProcessListSummary.data === null) {
          setProcessData([]);
          setIsRequestLoading(false);
          throw new Error('Server failed to respond with valid process list data.');
        }

        const rawList = data.getProcessListSummary.data;
        const parsedList = parseListData(rawList);

        setProcessData(parsedList);
        setIsRequestLoading(false);
      },
      onError: () => {
        setProcessData([]);
        setIsRequestLoading(false);
        throw new Error('Server failed to respond with valid process list data.');
      },
    });
  };

  return { processData, fetchProcessListData, isRequestLoading };
}
