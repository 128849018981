import { gql } from '@apollo/client';

export const QUERY_SAVINGS_FOR_SINGLE_PROPOSAL = gql`
  query ProposalSavingsFeature($proposalId: ID!, $accessToken: String!) {
    proposalSavingsFeature(id: $proposalId, accessToken: $accessToken) {
      id
      createdAt
      lowerFlexibility
      upperFlexibility
      commissionModality
      guaranteeType
      periods
      group {
        id
        name
        legalName
        docType
        docNumber
      }
      proposalType
      round
      supply {
        unit {
          id
          name
        }
      }
      bids {
        id
        deadline
        lowerFlexibility
        upperFlexibility
        guaranteeType
        retailService
        contractType
        coverCceeTaxes
        trader {
          id
          name
          score
          documents {
            uri
            docType
          }
        }
        periods
        economy {
          year
          amount
        }
        savings {
          status
          isInsideScope
          retailCommission
          wholesaleCommission
          fixedPrice {
            inputPrices {
              period
              pricePerYear {
                year
                value
              }
            }
            groupReport {
              period
              npv
              totalCurrencyAmount
              totalPercentageAmount
              valuesPerYear {
                year
                energyPrice
                acrCost
                aclCost
                management
                totalSavingsAmount
              }
              initialExpenses
            }
            unitsReports {
              name
              period
              totalCurrencyAmount
              totalPercentageAmount
              valuesPerYear {
                year
                energyPrice
                acrCost
                aclCost
                management
                totalSavingsAmount
              }
              initialExpenses
            }
          }
          guaranteedSaving {
            inputPrices {
              period
              pricePerYear {
                year
                value
              }
            }
            groupReport {
              period
              npv
              totalCurrencyAmount
              totalPercentageAmount
              valuesPerYear {
                year
                energyPrice
                acrCost
                aclCost
                management
                totalSavingsAmount
              }
            }
            unitsReports {
              name
              period
              totalCurrencyAmount
              totalPercentageAmount
              valuesPerYear {
                year
                energyPrice
                acrCost
                aclCost
                management
                totalSavingsAmount
              }
            }
          }
        }
      }
    }
  }
`;
export const GET_PROCESS_BY_PROPOSAL_ID_FOR_SAVINGS = gql`
  query GetProcessAndGroupByProposalId($proposalId: ID!, $accessToken: String!) {
    getProcessByProposalIdForSavings(proposalId: $proposalId, accessToken: $accessToken) {
      error
      process {
        id
        name
      }
    }
  }
`;
