import React from 'react';
import { useTokenForProposalSavings } from '@hooks/bids/access-proposal';
import { useParams } from 'react-router-dom';

interface SavingsContext {
  tokenForProposal: string | null;
}

const savingsContext = React.createContext<SavingsContext>({} as SavingsContext);

export function useSavingsContext() {
  return React.useContext(savingsContext);
}

const SavingsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { proposalId } = useParams();
  const [tokenForProposal, setTokenForProposal] = React.useState<string | null>(null);
  const generateAccessToken = useTokenForProposalSavings();

  React.useEffect(() => {
    if (!proposalId) return;

    const fetchData = async () => {
      try {
        const provisoryToken = await generateAccessToken(proposalId);
        setTokenForProposal(provisoryToken);
      } catch (error) {
        setTokenForProposal(null);
      }
    };

    fetchData();
  }, [proposalId]);

  return <savingsContext.Provider value={{ tokenForProposal }}>{children}</savingsContext.Provider>;
};

export default SavingsProvider;
