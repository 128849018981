import { useMutation } from '@apollo/client';
import { AccessTokenGraphQLResponse } from './types';
import { GET_ACCESS_TOKEN_FOR_SAVINGS } from './mutation';

export function useTokenForProposalSavings() {
  const [mutationFunction] = useMutation<AccessTokenGraphQLResponse, { id: string }>(GET_ACCESS_TOKEN_FOR_SAVINGS);

  const generateAccessToken = async (proposalId: string) => {
    const { data, errors } = await mutationFunction({ variables: { id: proposalId } });

    if (errors !== undefined || !data) throw new Error('Houve um erro e o servidor não respondeu como esperado.');

    return data.createProposalAccessToken;
  };

  return generateAccessToken;
}
